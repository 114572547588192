<script setup lang="ts">
import RunningLine from '@/components/common/RunningLine.vue';
import BtnPrimary from '@/components/ui/BtnPrimary.vue';
import { SectionIds, BenefitIds } from '@/types/enums';

const sectionId = ref(SectionIds.About);
const config = useRuntimeConfig();

const handleApply = () => {
  // const link = config.public.membershipLink;
  const link =
    'https://wexelerate-membership.notion.site/1308e130c0a980a08eecc8c81833109c';

  window.open(link, '_blank');
};
</script>

<template>
  <section class="about-us-container" :id="sectionId">
    <RunningLine
      :duration="20"
      :estimated-width="800"
      :height="153"
      classNames="running-line"
    >
      <div class="line-content-item">weXelerate Track record</div>
      <div class="line-content-item bold">6 BATCHES</div>
      <div class="line-content-item">200 STARTUPS</div>
      <div class="line-content-item bold">€ 650m raised</div>
      <div>{{ ' ' }}</div>
    </RunningLine>

    <div
      class="about-us-text-content-container background-primary d-flex align-center justify-center"
    >
      <div class="about-us-text-content d-flex flex-column">
        <div class="section-title text-primary-purple">HOW IT WORKS</div>
        <div class="about-us-title text-white">The venturecake method:</div>
        <div class="about-us-title text-lime-green mb-8">
          “Add a layer, take a slice”
        </div>
        <div class="about-us-text text-white">
          Every founder puts a share of their startup equity into the “batch
          cake” and gets a diversified piece of the whole “batch cake” in
          return.
        </div>
      </div>
      <div class="about-us-cake-logo">
        <nuxt-img src="/images/AboutUsCakeLogo.png" />
      </div>
    </div>
    <div
      class="how-it-works-container background-primary d-flex align-center justify-center"
    >
      <div class="how-it-works-logo">
        <nuxt-img src="/images/HowItWorksLogo.png" />
      </div>
      <div
        class="about-us-text-content d-flex flex-column justify-space-between"
      >
        <!-- <div class="section-title text-primary-purple">how it works</div> -->
        <span class="about-us-title text-white">
          Building the future
          <span class="about-us-title text-lime-green mb-8 ml-1">
            in collabor<span>ation</span>
          </span>
        </span>
        <div class="about-us-text text-white">
          By sharing success with all participating startups, and integrating
          them into our expanding ecosystem, we encourage collaboration, bring
          together the brightest minds and drive impactful innovation
        </div>

        <div
          class="apply-to-join-container d-flex align-center justify-space-between"
        >
          <BtnPrimary
            title="Apply to join!"
            variant="outlined"
            @callback="handleApply"
          />

          <div class="join-us d-flex justify-center align-center flex-wrap">
            <div class="join-us-logos d-flex align-start">
              <img
                width="55"
                src="@/assets/images/co-owners/2.png"
                alt="co-owner-2"
              />
              <img
                width="55"
                src="@/assets/images/co-owners/3.png"
                alt="co-owner-3"
              />
              <img
                width="55"
                src="@/assets/images/co-owners/logo.png"
                alt="co-owner-logo"
              />
            </div>
            <div class="join-us-text ml-5 d-flex flex-column align-start">
              <span class="text-soft-teal">Join the co-owned accelerator</span>
              <span class="text-soft-teal"
                >powered <span class="text-white">by wexelerate</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :id="BenefitIds.Benefit3">
      <div
        class="co-working-container-desktop d-flex align-start justify-center"
      >
        <div class="w-50 co-working-img text-right co-working-img-1">
          <nuxt-img height="708px" src="/images/co-working/CoWorking-1.webp" />

          <div class="co-working-banner d-flex flex-column">
            <div class="banner-title">co-working</div>
            <div class="banner-subtitle">
              Praterstrasse 1, 1020 Vienna, Austria
            </div>
          </div>
        </div>
        <div class="w-50 d-flex flex-column justify-start background-white">
          <div class="h-50 co-working-img text-left co-working-img-2 d-flex">
            <nuxt-img
              height="350px"
              src="/images/co-working/CoWorking-2.webp"
            />
          </div>
          <div class="d-flex justify-start">
            <div class="co-working-img w-50 co-working-img-3">
              <nuxt-img
                height="350px"
                src="/images/co-working/CoWorking-3.webp"
              />
            </div>
            <div class="co-working-img w-50 co-working-img-4">
              <nuxt-img
                height="350px"
                src="/images/co-working/CoWorking-4.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="co-working-container-mobile d-flex flex-column">
        <div class="co-working-img-mobile w-100">
          <nuxt-img src="/images/co-working/CoWorking-1.png" />

          <div class="co-working-banner d-flex flex-column">
            <div class="banner-title">co-working</div>
            <div class="banner-subtitle">
              Praterstrasse 1, 1020 Vienna, Austria
            </div>
          </div>
        </div>
        <div class="co-working-img-mobile w-100">
          <nuxt-img src="/images/co-working/CoWorking-2.jpg" />
        </div>
        <div class="co-working-img-mobile w-100">
          <nuxt-img src="/images/co-working/CoWorking-3.jpg" />
        </div>
        <div class="co-working-img-mobile w-100">
          <nuxt-img src="/images/co-working/CoWorking-4.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.about-us-container {
  // background-color: rgb(var(--v-theme-primary));
}

.running-line {
  background-color: rgb(var(--v-theme-lime-green));
}

.bold {
  font-weight: 600 !important;
}

.line-content-item {
  font-size: 62px !important;
  text-transform: uppercase;
  font-weight: 400;
  font-style: italic;
  white-space: nowrap;
}

.about-us-text-content {
  width: 50% !important;
  max-width: 580px !important;
  margin: 139px 70px 37px !important;
}

.co-working-img {
  overflow: hidden !important;
}

.section-title {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.about-us-title {
  font-size: 60px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  word-break: break-word;
}
.about-us-text {
  font-size: 18px;
  font-weight: 320;
}

.about-us-cake-logo {
  width: 50%;
  margin: 57px 25px 37px 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.how-it-works-logo {
  width: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.join-us {
  img {
    margin-left: -19px;
  }
}

.co-working-img-1 {
  position: relative;
  img {
    object-fit: cover !important;
    padding: 6px 0 6px 6px;
    max-height: 700px;
  }

  .co-working-banner {
    background-color: rgb(var(--v-theme-white));
    position: absolute;
    top: 24px;
    left: 24px;
    padding: 24px;
    color: rgb(var(--v-theme-primary));
    word-break: break-word;
    max-width: 300px;
    width: 100%;

    .banner-title {
      font-size: 60px;
      font-weight: 900;
      line-height: 54px;
      text-align: left;
      text-decoration-skip-ink: none;
      text-transform: uppercase;
    }

    .banner-subtitle {
      font-size: 15px;
      font-weight: 320;
      line-height: 24px;
      text-align: left;
      text-decoration-skip-ink: none;
    }
  }
}

.co-working-img-2 {
  overflow: hidden !important;
  img {
    padding: 6px 6px 0 6px;
  }
}

.co-working-img-3 {
  img {
    padding: 6px;
  }
}

.co-working-img-4 {
  img {
    padding: 6px;
  }
}

.apply-to-join-container {
  margin-top: 133px !important;
  margin-bottom: 60px !important;
}

.co-working-container-desktop {
  padding: 190px 160px;
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
}

.co-working-img-1 {
  img {
    width: 100%;
    object-fit: contain;
  }
}

.co-working-container-mobile {
  margin: 60px 20px;
  display: none !important;
}

@media screen and (max-width: 1400px) {
  .running-line {
    height: 100px !important;
    .ticker-content {
      .line-content-item {
        font-size: 32px !important;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .about-us-text-content {
    width: 45%;
    max-width: 580px;
    margin: 33px 70px 37px !important;
  }

  .about-us-title {
    // font-size: 48px !important;
  }

  .about-us-cake-logo {
    width: 55%;
    margin: 57px 33px 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .how-it-works-logo {
    width: 55%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .apply-to-join-container {
    margin-top: 100px !important;
    margin-bottom: 33px !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;

    .join-us {
      margin-left: 15px !important;
      margin-bottom: 20px !important;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .running-line {
    height: 153px !important;
    .ticker-content {
      .line-content-item {
        font-size: 62px !important;
        line-height: 86.8px !important;
        letter-spacing: 1px !important;
      }
    }
  }

  .about-us-text-content-container {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }

  .about-us-cake-logo,
  .how-it-works-logo {
    max-width: 720px;
    padding-top: 57px;
    width: 100%;
    margin: 0 !important;
  }

  .how-it-works-logo {
    margin-bottom: 40px !important;
  }

  .about-us-text-content {
    width: 100% !important;
    padding: 40px 20px 60px !important;
    margin: 0 !important;
  }

  .about-us-title {
    font-size: 60px !important;
    font-weight: 900 !important;

    word-break: break-word;
  }

  .how-it-works-container {
    flex-direction: column !important;
    align-items: center !important;
  }

  .how-it-works-logo {
    width: 100% !important;
  }

  .apply-to-join-container {
    display: flex !important;
    flex-direction: row !important;
  }

  .co-working-container-desktop {
    display: none !important;
  }

  .co-working-container-mobile {
    max-width: 622px;
    margin: 160px auto;
    padding: 0 32px;
    display: block !important;
  }

  .co-working-img-mobile {
    position: relative;
    overflow: hidden !important;
    height: 350px;
    margin-bottom: 10px;

    img {
      width: 100%;
      padding: 6px;
      height: 100%;
      object-fit: cover;
    }

    .co-working-banner {
      background-color: rgb(var(--v-theme-white));
      position: absolute;
      top: 24px;
      left: 24px;
      padding: 14px;
      color: rgb(var(--v-theme-primary));
      word-break: break-word;
      max-width: 150px;
      width: 100%;

      .banner-title {
        font-size: 33.6px;
        font-weight: 900;
        line-height: 30.24px;
        text-align: left;
        text-decoration-skip-ink: none;
        text-transform: uppercase;
      }

      .banner-subtitle {
        font-size: 8.4px;
        font-weight: 320;
        line-height: 13.44px;
        text-align: left;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .running-line {
    height: 70px !important;
    .ticker-content {
      .line-content-item {
        font-size: 28px !important;
      }
    }
  }

  .about-us-text-content-container {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }

  .about-us-cake-logo {
    width: 100%;
    margin: 0 !important;
    padding: 30px 10px 20px !important;
  }

  .about-us-text-content {
    width: 100% !important;
    padding: 40px 20px 60px !important;
    margin: 0 !important;
  }

  .about-us-text {
    font-size: 15px !important;
    margin-top: 30px !important;
  }

  .about-us-title {
    font-size: 42px !important;
    font-weight: 800 !important;

    word-break: break-word;
  }

  .how-it-works-container {
    flex-direction: column !important;
    align-items: center !important;
  }

  .how-it-works-logo {
    width: 100% !important;
  }

  .apply-to-join-container {
    margin-top: 50px !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;

    :deep(.v-btn) {
      width: 100%;
    }

    .join-us {
      flex-direction: column !important;
      align-items: flex-start !important;

      .join-us-logos {
        margin-left: 15px !important;
      }

      .join-us-text {
        margin: 20px 0 30px !important;
      }
    }
  }

  .co-working-container-desktop {
    display: none !important;
  }

  .co-working-container-mobile {
    display: block !important;
  }

  .co-working-img-mobile {
    position: relative;
    overflow: hidden !important;
    height: 350px;
    margin-bottom: 10px;

    img {
      width: 100%;
      padding: 6px;
      height: 100%;
      object-fit: cover;
    }

    .co-working-banner {
      background-color: rgb(var(--v-theme-white));
      position: absolute;
      top: 24px;
      left: 24px;
      padding: 14px;
      color: rgb(var(--v-theme-primary));
      word-break: break-word;
      max-width: 150px;
      width: 100%;

      .banner-title {
        font-size: 33.6px;
        font-weight: 900;
        line-height: 30.24px;
        text-align: left;
        text-decoration-skip-ink: none;
        text-transform: uppercase;
      }

      .banner-subtitle {
        font-size: 8.4px;
        font-weight: 320;
        line-height: 13.44px;
        text-align: left;
        text-decoration-skip-ink: none;
      }
    }
  }
}
</style>
