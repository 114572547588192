<script setup lang="ts">
import corporates1 from '@/assets/images/svg/partners/corporates-1.svg';
import corporates2 from '@/assets/images/svg/partners/corporates-2.svg';
import corporates3 from '@/assets/images/svg/partners/corporates-3.svg';
import corporates4 from '@/assets/images/svg/partners/corporates-4.svg';
import corporates5 from '@/assets/images/svg/partners/corporates-5.svg';

const props = defineProps<{
  height?: number;
  classNames?: string;
  withGradient?: boolean;
  direction?: 'left' | 'right';
}>();

const tickerBlocks = ref<number>(2);
const contentWidth = ref(861);

const scrollDirection = computed(() => props.direction || 'left');
</script>

<template>
  <div
    class="running-line w-100 position-relative"
    :class="[props.classNames, `scroll-${scrollDirection}`]"
    :style="{ height: `${props.height}px` }"
  >
    <template v-for="index in tickerBlocks" :key="index">
      <div
        class="ticker-content h-100"
        :style="{
          width: 'max-content',
          justifyContent: 'space-around !important',
          gap: '120px !important',
          left: `${(index - 1) * contentWidth}px`,
        }"
      >
        <div
          class="trusted-partners-wrapper h-100 w-100 d-flex align-center justify-space-around"
        >
          <div class="icon-running-line-wrapper">
            <corporates1 class="trusted-partner-icon" />
          </div>
          <div class="icon-running-line-wrapper">
            <corporates2 class="trusted-partner-icon" />
          </div>
          <div class="icon-running-line-wrapper">
            <corporates3 class="trusted-partner-icon" />
          </div>
          <div class="icon-running-line-wrapper">
            <corporates4 class="trusted-partner-icon" />
          </div>
          <div class="icon-running-line-wrapper">
            <corporates5 class="trusted-partner-icon" />
          </div>
        </div>
      </div>
    </template>
    <template v-if="props.withGradient">
      <div class="gradient-overlay gradient-left"></div>
      <div class="gradient-overlay gradient-right"></div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;

  $duration: 30s;

  .ticker-content {
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: flex-start;
    &:not(.scroll-right) {
      animation: tickerLeft $duration infinite linear forwards;
    }
  }

  &.scroll-right .ticker-content {
    animation: tickerRight $duration infinite linear forwards;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    z-index: 1;
  }

  .gradient-left {
    left: 0;
    background: linear-gradient(
      90deg,
      #02363e 13.57%,
      rgba(2, 54, 62, 0) 110.71%
    );
  }

  .gradient-right {
    right: 0;
    background: linear-gradient(
      270deg,
      #02363e 13.57%,
      rgba(2, 54, 62, 0) 110.71%
    );
  }
}

.trusted-partners-wrapper {
  position: relative;
  background: rgb(var(--v-theme-primary));
}

.icon-running-line-wrapper {
  margin-right: 60px;
}

.trusted-partner-icon {
  opacity: 0.6;
}

@keyframes tickerLeft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes tickerRight {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
