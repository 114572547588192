<script setup lang="ts">
import RunningLine from '@/components/common/RunningLine.vue';
import TrustedPartner1Icon from '@/assets/images/svg/trusted-partners/trusted-partner-1.svg';
import TrustedPartner2Icon from '@/assets/images/svg/trusted-partners/trusted-partner-2.svg';
import TrustedPartner3Icon from '@/assets/images/svg/trusted-partners/trusted-partner-3.svg';
import TrustedPartner4Icon from '@/assets/images/svg/trusted-partners/trusted-partner-4.svg';
import TrustedPartner5Icon from '@/assets/images/svg/trusted-partners/trusted-partner-5.svg';
import TrustedPartner6Icon from '@/assets/images/svg/trusted-partners/trusted-partner-6.svg';
import BenefitCard from '@/components/common/BenefitCard.vue';

import TrustedPartnersLine from '@/components/ui/RunningLines/TrustedPartnersLine.vue';

import { BenefitIds } from '@/types/enums';

import cardBgPurple from '@/assets/images/card-bg-purple.png';
import cardBgLime from '@/assets/images/card-bg-lime.png';
import cardBgLavender from '@/assets/images/card-bg-lavender.png';
import cardBgOrange from '@/assets/images/card-bg-orange.png';

const benefitCards = [
  {
    name: 'Benefit 1',
    title: 'GROW THROUGH MENTOR-SHIP',
    description:
      'Seasoned mentors from the most relevant fields and industries in Austria to support you in individual sessions, on demand.',
    textColor: 'text-primary-purple',
    cardBg: cardBgPurple,
    benefitId: BenefitIds.Benefit1,
    className: 'benefit-1',
  },
  {
    name: 'Benefit 2',
    title: 'MARKET PROVE YOUR PRODUCTS WITH',
    description:
      'Our broad ecosystem and partner landscape will support you in testing, piloting and scaling your product.',
    textColor: 'text-lime-green',
    cardBg: cardBgLime,
    benefitId: BenefitIds.Benefit2,
    className: 'benefit-2',
  },
  {
    name: 'Benefit 3',
    title: 'GET ACCESS TO THE WEXELERATE HUB',
    description:
      'Secure your workspace in Vienna’s prime business hub – a vibrant environment for events, networking, and collaboration, where your ideas can truly take shape.',
    textColor: 'text-primary-lavender',
    cardBg: cardBgLavender,
    benefitId: BenefitIds.Benefit3,
    className: 'benefit-3',
  },
  {
    name: 'Benefit 4',
    title: 'GET READY FOR FUNDING',
    description:
      'Our main goal is to support you in getting ready to be funded via external vehicles (Austrian Public & Private Support & VCs in particular).',
    textColor: 'text-primary-orange',
    cardBg: cardBgOrange,
    benefitId: BenefitIds.Benefit4,
    className: 'benefit-4',
  },
];

const handleLearnMore = (benefitId: BenefitIds) => {
  const element = document.getElementById(`${benefitId}`);

  if (element) {
    const elementRect = element.getBoundingClientRect();
    const offset = window.scrollY + elementRect.top;

    const headerHeight = 90;

    const duration = 1200;
    const start = window.scrollY;
    const change = offset - headerHeight - start;
    const increment = 20;

    const animateScroll = (elapsedTime: number) => {
      elapsedTime += increment;
      const position = easeInOutQuad(elapsedTime, start, change, duration);
      window.scrollTo(0, position);
      if (elapsedTime < duration) {
        setTimeout(() => animateScroll(elapsedTime), increment);
      }
    };

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    animateScroll(0);
  }
};
</script>

<template>
  <section class="trusted-partners-container">
    <div
      class="trusted-partners-container d-flex align-start justify-space-between w-100"
    >
      <div class="first-block d-flex flex-column">
        <span
          class="trusted-partner-of-text d-flex justify-start align-center text-soft-teal"
          >Trusted partner of weXelerate:</span
        >
        <BenefitCard
          :key="benefitCards[0].name"
          :name="benefitCards[0].name"
          :title="benefitCards[0].title"
          :description="benefitCards[0].description"
          :textColor="benefitCards[0].textColor"
          :card-bg="benefitCards[0].cardBg"
          :benefit-id="benefitCards[0].benefitId"
          :is-last="false"
          @learn-more="handleLearnMore"
          :class-name="benefitCards[0].className"
        >
          <h3 class="card-title">
            GROW
            <div :class="benefitCards[0].textColor">THROUGH MENTOR-SHIP</div>
          </h3>
        </BenefitCard>
      </div>
      <div>
        <TrustedPartnersLine
          :height="124"
          classNames="border-top-secondary trusted-partners-running-line"
          typeContent="images"
        />
        <!-- <RunningLine
          :height="124"
          classNames="border-top-secondary trusted-partners-running-line"
          typeContent="images"
          :duration="20"
        >
          <div
            class="trusted-partners-wrapper h-100 w-100 d-flex align-center justify-space-around"
          >
            <div class="icon-running-line-wrapper">
              <TrustedPartner1Icon class="trusted-partner-icon" />
            </div>
            <div class="icon-running-line-wrapper">
              <TrustedPartner2Icon class="trusted-partner-icon" />
            </div>
            <div class="icon-running-line-wrapper">
              <TrustedPartner3Icon class="trusted-partner-icon" />
            </div>
            <div class="icon-running-line-wrapper">
              <TrustedPartner4Icon class="trusted-partner-icon" />
            </div>
          </div>
        </RunningLine> -->
        <div class="benefit-cards-container d-flex">
          <div class="first-block-mobile">
            <BenefitCard
              :key="benefitCards[0].name"
              :name="benefitCards[0].name"
              :title="benefitCards[0].title"
              :description="benefitCards[0].description"
              :textColor="benefitCards[0].textColor"
              :card-bg="benefitCards[0].cardBg"
              :benefit-id="benefitCards[0].benefitId"
              :is-last="false"
              @learn-more="handleLearnMore"
              :class-name="benefitCards[0].className"
            >
              <h3 class="card-title">
                GROW
                <div :class="benefitCards[0].textColor">
                  THROUGH MENTOR-SHIP
                </div>
              </h3>
            </BenefitCard>
          </div>
          <BenefitCard
            :key="benefitCards[1].name"
            :name="benefitCards[1].name"
            :title="benefitCards[1].title"
            :description="benefitCards[1].description"
            :textColor="benefitCards[1].textColor"
            :card-bg="benefitCards[1].cardBg"
            :benefit-id="benefitCards[1].benefitId"
            :is-last="false"
            @learn-more="handleLearnMore"
            :class-name="benefitCards[1].className"
          >
            <h3 class="card-title">
              MARKET
              <div :class="benefitCards[1].textColor">PROVE YOUR PRODUCTS</div>
            </h3>
          </BenefitCard>
          <BenefitCard
            :key="benefitCards[2].name"
            :name="benefitCards[2].name"
            :title="benefitCards[2].title"
            :description="benefitCards[2].description"
            :textColor="benefitCards[2].textColor"
            :card-bg="benefitCards[2].cardBg"
            :benefit-id="benefitCards[2].benefitId"
            :is-last="false"
            @learn-more="handleLearnMore"
            :class-name="benefitCards[2].className"
          >
            <h3 class="card-title">
              GET ACCESS TO THE
              <div :class="benefitCards[2].textColor">WEXELERATE HUB</div>
            </h3>
          </BenefitCard>
          <BenefitCard
            :key="benefitCards[3].name"
            :name="benefitCards[3].name"
            :title="benefitCards[3].title"
            :description="benefitCards[3].description"
            :textColor="benefitCards[3].textColor"
            :card-bg="benefitCards[3].cardBg"
            :benefit-id="benefitCards[3].benefitId"
            :is-last="true"
            @learn-more="handleLearnMore"
            :class-name="benefitCards[3].className"
          >
            <h3 class="card-title">
              <div :class="benefitCards[3].textColor">GET READY</div>
              FOR FUNDING
            </h3>
          </BenefitCard>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.trusted-partner-icon {
  opacity: 0.6;
}

.trusted-partner-of-text {
  border-top: 1px solid rgb(var(--v-theme-secondary));
}

.trusted-partners-container {
  background: rgb(var(--v-theme-primary));
}

.trusted-partners-wrapper {
  position: relative;
  background: rgb(var(--v-theme-primary));
}

.trusted-partner-of-text {
  height: 124px !important;
  padding: 0 70px;
  background: rgb(var(--v-theme-primary));

  border-right: 1px solid rgb(var(--v-theme-secondary));
}

.card-title {
  word-break: break-word !important;
  margin: 22px 0 16px !important;
}

.first-block-mobile {
  display: none;
}

.icon-running-line-wrapper {
  margin-right: 70px;
}

.benefit-1 {
  &:hover {
    :deep(.card-title) {
      color: rgb(var(--v-theme-primary-purple));
    }
  }
}

.benefit-2 {
  &:hover {
    :deep(.card-title) {
      color: rgb(var(--v-theme-lime-green));
    }
  }
}

.benefit-3 {
  &:hover {
    :deep(.card-title) {
      color: rgb(var(--v-theme-primary-lavender));
    }
  }
}

.benefit-4 {
  &:hover {
    :deep(.card-title) {
      color: rgb(var(--v-theme-primary-orange));
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .benefit-card {
    min-height: auto !important;

    :deep(.v-btn) {
      margin-top: 30px !important;
    }
  }

  .first-block {
    display: none !important;
  }

  .first-block-mobile {
    display: block;
  }

  .benefit-cards-container {
    flex-direction: column !important;
  }

  .trusted-partners-running-line {
    height: 95px !important;
  }
}

@media screen and (max-width: 576px) {
  .benefit-card {
    min-height: auto !important;

    :deep(.v-btn) {
      margin-top: 30px !important;
    }
  }

  .first-block {
    display: none !important;
  }

  .first-block-mobile {
    display: block;
  }

  .benefit-cards-container {
    flex-direction: column !important;
  }

  .trusted-partners-running-line {
    height: 95px !important;
  }
}
</style>
